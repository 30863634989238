var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card col-xl-12 col-lg-12"},[_c('a-modal',{attrs:{"dialog-style":{
        top: '2%',
      },"title":_vm.visibleDetailsPN
          ? _vm.$t('sms.detailsAutoPnTitle')
          : _vm.$t('sms.detailsAutoSmsTitle'),"visible":_vm.visibleDetails || _vm.visibleDetailsPN,"width":"60%"},on:{"cancel":function () {
          _vm.visibleDetails = false;
          _vm.visibleDetailsPN = false;
        }}},[_c('a-list',{attrs:{"item-layout":"horizontal","data-source":_vm.visibleDetailsPN ? _vm.optionsPN : _vm.visibleDetails ? _vm.options : []},scopedSlots:_vm._u([{key:"renderItem",fn:function(item){return _c('a-list-item',{},[_c('a-list-item-meta',[_c('div',{attrs:{"slot":"description"},slot:"description"},[(item.text)?_c('div',[_vm._v(_vm._s(item.text))]):_vm._e(),(item.text2)?_c('div',[_vm._v(_vm._s(item.text2))]):_vm._e()]),_c('a',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(_vm.$t(item.tname))+" ")]),_c('div',{attrs:{"slot":"avatar"},slot:"avatar"},[_c('a-icon',{staticStyle:{"font-size":"21px"},attrs:{"type":item.icon}})],1)])],1)}}])}),_c('template',{slot:"footer"},[_c('a-button',{key:"back",on:{"click":function () {
              _vm.visibleDetails = false;
              _vm.visibleDetailsPN = false;
            }}},[_vm._v(" "+_vm._s(_vm.$t("action.annuler"))+" ")])],1)],2),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"ant-page-header-heading font-size-24 mb-3"},[_c('i',{staticClass:"fa fa-envelope mr-2"}),_vm._v(_vm._s(_vm.$t("sms.autoSms"))+" "),_c('div',{staticStyle:{"position":"absolute","right":"40px","top":"15px"}},[_c('a-button',{attrs:{"icon":"question-circle"},on:{"click":function($event){_vm.visibleDetails = true}}},[_vm._v(" "+_vm._s(_vm.$t("sms.deatilsAutoSms"))+" ")])],1)]),(_vm.loadingData)?_c('a-skeleton',{attrs:{"active":"","paragraph":{ rows: 6 }}}):_c('div',{staticClass:"row"},_vm._l((_vm.options),function(item){return _c('div',{key:item.key,staticClass:"col-lg-3 mt-4",staticStyle:{"display":"flex"}},[_c('a-tree',{attrs:{"checkable":"","default-expand-all":true,"disabled":_vm.disabledOptions},model:{value:(_vm.smsSettings[item.key]),callback:function ($$v) {_vm.$set(_vm.smsSettings, item.key, $$v)},expression:"smsSettings[item.key]"}},[_c('a-tree-node',{key:"all",attrs:{"icon":item.icon,"title":_vm.$t(item.tname)}},[_c('a-tree-node',{key:"pere",attrs:{"title":_vm.$t('sms.pere')}}),_c('a-tree-node',{key:"mere",attrs:{"title":_vm.$t('sms.mere')}})],1)],1)],1)}),0),_c('a-divider'),_c('div',{staticClass:"mt-4"},[_c('a-button',{attrs:{"type":_vm.checkSaved || _vm.loadingData || _vm.loadingSave || _vm.disabledOptions
              ? ''
              : 'primary',"loading":_vm.loadingSave,"disabled":_vm.checkSaved || _vm.loadingData || _vm.loadingSave || _vm.disabledOptions,"icon":"save"},on:{"click":function () {
              _vm.handleSave();
            }}},[_vm._v(" "+_vm._s(_vm.$t("all.save"))+" ")]),_c('a-button',{staticClass:"ml-2",attrs:{"loading":_vm.loadingSave,"disabled":_vm.loadingData || _vm.loadingSave || _vm.disabledOptions || _vm.checkSaved,"icon":"undo"},on:{"click":_vm.handleReset}},[_vm._v(" "+_vm._s(_vm.$t("action.reset"))+" ")])],1)],1)],1),_c('div',{staticClass:"card col-xl-12 col-lg-12 mt-2"},[_c('div',{staticClass:"card-body mt-3"},[_c('div',{staticClass:"ant-page-header-heading font-size-24 mb-3"},[_c('i',{staticClass:"fa fa-envelope mr-2"}),_vm._v(_vm._s(_vm.$t("sms.autoPn"))+" "),_c('div',{staticStyle:{"position":"absolute","right":"40px","top":"15px"}},[_c('a-button',{attrs:{"icon":"question-circle"},on:{"click":function($event){_vm.visibleDetailsPN = true}}},[_vm._v(" "+_vm._s(_vm.$t("sms.deatilsAutoSms"))+" ")])],1)]),(_vm.loadingDataPN)?_c('a-skeleton',{attrs:{"active":"","paragraph":{ rows: 6 }}}):_c('div',{staticClass:"row"},_vm._l((_vm.optionsPN),function(item){return _c('div',{key:item.key,staticClass:"col-lg-3 mt-4",staticStyle:{"display":"flex"}},[_c('a-checkbox',{attrs:{"checked":_vm.pnSettings[item.key]},on:{"change":function (e) { return (_vm.pnSettings[item.key] = e.target.checked); }}},[_vm._v(" "+_vm._s(_vm.$t(item.tname))+" ")])],1)}),0),_c('a-divider'),_c('div',{staticClass:"mt-4"},[_c('a-button',{attrs:{"type":_vm.checkSavedPN || _vm.loadingDataPN || _vm.loadingSavePN ? '' : 'primary',"loading":_vm.loadingSave,"disabled":_vm.checkSavedPN || _vm.loadingDataPN || _vm.loadingSavePN,"icon":"save"},on:{"click":function () {
              _vm.handleSavePN();
            }}},[_vm._v(" "+_vm._s(_vm.$t("all.save"))+" ")]),_c('a-button',{staticClass:"ml-2",attrs:{"loading":_vm.loadingSavePN,"disabled":_vm.loadingDataPN || _vm.loadingSavePN || _vm.checkSavedPN,"icon":"undo"},on:{"click":_vm.handleResetPN}},[_vm._v(" "+_vm._s(_vm.$t("action.reset"))+" ")])],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }